import restClient from 'rest'
import pathPrefix from 'rest/interceptor/pathPrefix'
import errorCode from 'rest/interceptor/errorCode'
import mime from 'rest/interceptor/mime'

const SERVER_URL="https://admin.dev.orane.online/api";

const rest = restClient.chain(errorCode, { code: 500 })
                        .chain(errorCode, { code: 401 });

const errorManager = (history) => (err) => {
  if(err.status.code === 401) {
    history.replace('/logout');
  } else {
    throw err;
  }
};

const get = (username, token, history) => (ressource) => {
  return rest({
    path: `${SERVER_URL}/${ressource}?session_username=${username}&session_token=${token}`,
    method: 'GET'
  }).then((data) => JSON.parse(data.entity)).catch(errorManager(history))
}

const post = (username, token, history) => (ressource,datas) => {
  return rest({
    path: `${SERVER_URL}/${ressource}`,
    method: 'POST',
    headers: {
     'Content-Type': 'application/json'
    },
    entity: JSON.stringify(Object.assign({session_username: username,session_token:token},datas))
   }).then((data) => JSON.parse(data.entity)).catch(errorManager(history))
}

const put = (username, token, history) => (ressource,datas) => {
  return rest({
    path: `${SERVER_URL}/${ressource}`,
    method: 'PUT',
    headers: {
     'Content-Type': 'application/json'
    },
    entity: JSON.stringify(Object.assign({session_username: username,session_token:token},datas))
  }).then((data) => JSON.parse(data.entity)).catch(errorManager(history))
}

const del = (username, token, history) => (ressource,datas) => {
  return rest({
    path: `${SERVER_URL}/${ressource}`,
    method: 'DELETE',
    headers: {
     'Content-Type': 'application/json'
    },
    entity: JSON.stringify(Object.assign({session_username: username,session_token:token},datas))
  }).then((data) => JSON.parse(data.entity)).catch(errorManager(history))
}

const withAuth = (username, token) => (pathOrData) => {
  if(typeof pathOrData === "string") {
    return `${SERVER_URL}/${pathOrData}?session_username=${username}&session_token=${token}`;
  } else {
    return Object.assign({session_username: username,session_token:token}, pathOrData);
  }
}

export default function(username = undefined, token = undefined, history = undefined) {
  return {
    get: get(username,token,history),
    post: post(username,token,history),
    put: put(username,token,history),
    del: del(username,token,history),
    withAuth: withAuth(username,token)
  };
}
